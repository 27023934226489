<template>
  <div class="header-wrapper">
    <div v-if="environment === 'staging' || environment === 'development'" class="header-banner">
      <!-- eslint-disable-next-line -->
      Orders will not be fulfilled. Environment: {{ environment }}
    </div>
    <div class="header clamped">
      <NuxtLink to="/" class="logo-link">
        <PrismicImage :field="logo" width="185" height="34" class="logo" />
      </NuxtLink>

      <MainMenu v-if="!simpleHeader" class="menu" />

      <div v-if="!simpleHeader" class="sf-header__icons">
        <SfButton
          v-e2e="'app-header-account'"
          class="sf-button--pure sf-header__action"
          data-testid="accountIcon"
          aria-label="Account"
          @click="handleAccountClick"
        >
          <SvgImage
            :icon="accountIcon"
            :label="$t('Account')"
            width="20"
            height="20"
            :class="{'sf-header__icon': true}"
          />
        </SfButton>
        <span v-show="isAuthenticated">
          <SfButton
            class="sf-button--pure sf-header__action"
            data-testid="wishlistIcon"
            aria-label="Wishlist"
            @click="toggleWishlistSidebar"
          >
            <SvgImage
              :icon="wishlistHasProducts ? 'heart_fill' : 'heart'"
              :label="$t('Wishlist')"
              width="20"
              height="20"
              class="sf-header__icon"
              :class="{'sf-header__icon': true}"
            />
            <SfBadge
              v-if="wishlistHasProducts"
              class="sf-badge--number cart-badge"
            >
              {{ wishlistItemsQty }}
            </SfBadge>
          </SfButton>
        </span>
        <SfButton
          v-e2e="'app-header-cart'"
          class="sf-button--pure sf-header__action"
          aria-label="Toggle cart sidebar"
          v-if="cartTotalItems > 0"
          @click="toggleCartSidebar"
        >
          <SvgImage
            icon="empty_cart"
            :label="$t('Cart')"
            width="20"
            height="20"
            class="sf-header__icon"
            :class="{
              'sf-header__icon is-active': cartTotalItems > 0,
            }"
          />
          <SfBadge
            v-if="cartTotalItems"
            class="sf-badge--number cart-badge"
            :class="{'is-flashing': cartIncreased}"
          >
            {{ cartTotalItems }}
          </SfBadge>
        </SfButton>
        <a href="/contact-us"
          class="lets-chat"
          aria-label="Contact SwiftOtter"
        >
          Let's Chat
        </a>
      </div>
    </div>
    <!--    <SearchResults
      v-if="isSearchOpen"
      :visible="isSearchOpen"
      :search-results="productSearchResults"
      @close="isSearchOpen = false"
    />-->
    <!--    <SfOverlay :visible="isSearchOpen" />-->
  </div>
</template>

<script>
import { SfButton, SfBadge, } from '@storefront-ui/vue';

import {
  computed,
  ref,
  watch,
  useRouter,
  useContext,
  onMounted,
  useRoute,
} from '@nuxtjs/composition-api';
import {
  useUiHelpers,
  useUiState,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import SvgImage from '~/components/General/SvgImage.vue';
import MainMenu from '~/components/Page/MainMenu.vue';
import { useCmsStore } from '~/modules/cms/stores/cms';

export default {
  components: {
    MainMenu,
    SvgImage,
    SfButton,
    SfBadge,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const cartTotalItems = computed(() => cart.value?.total_quantity ?? 0);
    const cartIncreased = ref(false);
    const simpleHeader = computed(() => route?.value?.path?.includes('/checkout'));

    const isSearchOpen = ref(false);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    onMounted(() => {
      window.requestIdleCallback(async () => {
        await loadCartTotalQty();

        await loadWishlistItemsCount();
      }, { timeout: 6250 });

      watch(cartTotalItems, (newAmount, oldAmount) => {
        if (newAmount > oldAmount) {
          cartIncreased.value = true;

          setTimeout(() => {
            cartIncreased.value = false;
          }, 1600);
        }
      });
    });

    return {
      accountIcon,
      cartTotalItems,
      cartIncreased,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      simpleHeader,
      environment: process.env.VSF_NUXT_APP_ENV
    };
  },
  data() {
    return {
      menu: [],
      logo: {}
    };
  },
  async fetch() {
    const cmsStore = useCmsStore();

    await cmsStore.loadSingle('menu');

    if (cmsStore.menu) {
      this.menu = cmsStore.menu.menu_items;
      this.logo = cmsStore.menu.logo || {};
    }
  }
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  background-color: $color-primary;
}

.header {
  color: #fff;
  padding: min(2vh, 1rem) min(2vw, 2rem) 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1.5em;
  row-gap: min(2vmin, 1rem);
  justify-content: space-between;
  --c-text: #fff;
  --c-link-hover: #c2c2c2;

  @include for-desktop {
    padding: var(--spacer-sm) var(--spacer-sm);
    flex-wrap: wrap;
  }

  ::v-deep .sf-header__navigation {
    display: block;
  }

  &__icons {
    @include for-mobile {
      --header-icons-display: flex;
      position: absolute;
      top: 20px;
    }
  }
}

.lets-chat {
  background-color: var(--c-primary);
  color: var(--c-secondary);
  font-family: var(--font-family--primary);
  font-size: var(--font-size-body);
  font-weight: bold;
  padding: 0.5em 1em;
  border-radius: var(--small-radius);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.header.clamped {
  margin-bottom: 0;
}

.sf-header__action {
  margin: 0;
}

.sf-header__icons {
  display: flex;
  gap: 0.5em;
  order: 5;

  @include for-desktop() {
    flex-basis: auto;
    gap: 1em;
  }
}

.logo-link {
  flex-shrink: 0;
  display: block;
  order: 0;
  min-width: 45%;

  @include for-desktop() {
    order: initial;
    min-width: 0;
  }
}

.menu {
  order: 10;
  flex-grow: 1;
  max-width: 800px;

  @include for-desktop() {
    order: initial;
  }
}

.header-on-top {
  z-index: 2;
}

.cart-badge {
  position: absolute;
  bottom: 40%;
  left: 40%;
}

.cart-badge.is-flashing {
  animation: flash 1500ms ease-in-out;
  transform-origin: right center;

  @keyframes flash {
    0% {
      transform: scale(100%);
    }

    30% {
      transform: scale(180%);
    }

    100% {
      transform: scale(100%);
    }
  }
}

.header-banner {
  text-align: center;
  font-size: $font-size-base;
  background-color: $color-yellow;
  padding: var(--spacer-sm);

  a {
    color: inherit;
    text-decoration: underline;
  }
}
</style>
